import * as React from "react";
import RoundedButton from "../sub-components/roundedButton";
import * as styles from './contactForm.module.scss';

const ContactForm = ({ title }) => {
    return (
        <div className={styles.container}>
            <div className={styles.centeredRow}>
                <h2>{title}</h2>
            </div>
            <div className={styles.formWrapper}>
                <div className={styles.formRowSplit}>
                    <label className={styles.labelOne} for="firstName">First Name *</label>
                    <input className={styles.inputOne} type="text" name="firstName"></input>
                    <label className={styles.labelTwo} for="lastName">Last Name *</label>
                    <input className={styles.inputTwo} type="text" name="lastName"></input>
                </div>
                <div className={styles.formRowSplit}>
                    <label className={styles.labelOne} for="email">Email *</label>
                    <input className={styles.inputOne} type="text" name="email"></input>
                    <label className={styles.labelTwo} for="phone">Phone *</label>
                    <input className={styles.inputTwo} type="text" name="phone"></input>
                </div>
                <div className={styles.formRow}>
                    <label for="message">Message *</label>
                    <textarea rows="3" name="message" />
                </div>
            </div>
        </div>
    );
}

export default ContactForm;