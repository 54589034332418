import React from "react";
import { graphql } from "gatsby";
import * as styles from "../../styles/contactUs.module.scss";
import DefaultLayout from "../../layouts";
import Seo from "../../components/seo";
import ContactForm from "../../components/contactForm";

const ContactUsPage = ({ data }) => {
    const contactUsData = data.directus.contactUs;
    return (
        <DefaultLayout>
            <Seo
                title={contactUsData.title}
                description={contactUsData.metaDescription} />
            <main>
                <div className={styles.containerContactUs}>
                    <div className={styles.titleContainer}>
                        <h1 className={styles.title}>{contactUsData.title}</h1>
                    </div>
                    <img src={contactUsData?.bannerImage?.imageFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={contactUsData?.bannerImage?.description} />
                    <div className={styles.contentContainer}>
                        <div dangerouslySetInnerHTML={{ __html: contactUsData.content }}></div>
                    </div>
                    <ContactForm title={contactUsData.formTitle} />
                </div>
            </main>
        </DefaultLayout>
    );
};

export const query = graphql`
query contactUs {
    directus {
      contactUs {
        title
        content
        formTitle
        metaDescription
        bannerImage {
            id
            description
            imageFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
      }
    }
  }
`;

export default ContactUsPage;